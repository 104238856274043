import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-prochain-concert',
  templateUrl: './prochain-concert.component.html',
  styleUrls: ['./prochain-concert.component.scss'],
})
export class ProchainConcertComponent implements OnInit {
  buildHash = environment.buildHash;

  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Prochain concert');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.CONCERTS);
  }
}
