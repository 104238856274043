<div class="container">
  <div class="row">
    <ng-container *ngFor="let album of albums; let i = index">
      <div *ngIf="i % COLUMNS_NUMBER === 0 && i !== 0" class="w-100 mb-4"></div>
      <div class="col align-self-center">
        <a [routerLink]="[album | slice: 7]">
          <img class="thumbnail" src="https://cplc.ch/gallery/{{ album }}/thumbnail.jpg" />
          <h3 class="text-center">{{ album | slice: 7 }}</h3>
        </a>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let i of [].constructor((COLUMNS_NUMBER - (albums?.length % COLUMNS_NUMBER)) % COLUMNS_NUMBER)"
    >
      <div class="col"></div>
    </ng-container>
  </div>
</div>
