import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlbumComponent } from './album/album.component';
import { GaleriePhotosComponent } from './galerie-photos/galerie-photos.component';

const routes: Routes = [
  { path: '', component: GaleriePhotosComponent },
  {
    path: ':album',
    component: AlbumComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GaleriePhotosRoutingModule {}
