<div class="text-center w-75 mx-auto">
  <p>
    Le programme de cette saison 2024-2025 sera élaboré à Vercorin début juillet lors de notre traditionnel stage d'été,
    <br />avec la participation de David Mercier, trompettiste.
  </p>

  <p *ngIf="false">
    Ces pièces seront rejouées le 4 février 2024 lors de notre traditionnel concert d'hiver à Chéserex (flyer
    ci-dessous).
  </p>

  <picture class="w-50 mx-auto py-4 d-block">
    <source srcset="{{ 'assets/prochain-concert.jpg?preventCaching=' + buildHash }}" />
    <img
      src="{{ 'assets/prochain-concert.jpg?preventCaching=' + buildHash }}"
      alt="Flyer du prochain concert du CPLC"
      width="400px"
    />
  </picture>

  <p>
    Si toutefois vous ne pouvez pas assister à l’un de nos concerts et souhaitez nous soutenir, ci-dessous nos
    coordonnées bancaires:
  </p>
  <div class="row">
    <div class="col-6">
      <p>
        Centre de Percussions de la Côte<br />
        p/a Claude Meynent<br />
        Rte du Creux-du-Mas 9<br />
        1185 Mont-sur-Rolle
      </p>
    </div>
    <div class="col-6">
      <p>
        CH58 0024 3243 G770 3199 0<br />
        UBS Switzerland AG, CH 8098 Zürich
      </p>
    </div>
  </div>
</div>
