<div class="w-75 text-center mx-auto">
  <picture class="d-block w-50 mx-auto mb-5">
    <source srcset="assets/20eme/Ors.jpg" />
    <img src="assets/20eme/Ors.jpg" alt="Portrait de Örs Kisfaludy." />
  </picture>

  <div class="text-justify">
    <p>Né à Budapest. Réfugié en Belgique puis en Ethiopie et dès 1961 en Suisse.</p>

    <p>
      Entre à 15 ans à l’Ecole Romande d’Art Dramatique de Lausanne. Dès 1964, commence une carrière de comédien sur les
      scènes de théâtre, dans les studios de radio et sur les plateaux de télévision.
    </p>

    <p>
      De 1968 à 1970, enseigne le théâtre au Conservatoire de Kinshasa (Congo) puis reprend sa carrière de comédien en
      Suisse, en France et en Belgique.
    </p>

    <p>De 1985 à 1990, anime une émission musicale à la Radio Suisse Romande sur les ondes d’Espace 2.</p>

    <p>
      A partir de 1986, se consacre à une carrière de récitant en participant à de nombreux concerts en Suisse et à
      l’étranger: France, Portugal, Espagne, Allemagne, Italie (Scala), Vénézuela, Etats-unis. Sous la direction de
      chefs tels que: Michel Corboz, André Charlet, Pascal Mayer, Marcello Viotti, Helmuth Rilling, Eric Leinsdorf,
      Jesus Lopez Cobos, Josep Pons, Hartmut Haenchen, Marek Janowski, Daniel Harding, Adam Fischer, James Levine et
      Pierre Boulez. Dans des oeuvres telles que: La Danse des Morts, Le Roi David, Jeanne d’Arc au Bûcher, Judith,
      Nicolas de Flue (Honegger), Les enfants à Bethléem (Pierné), Le Martyre de Saint Sébastien (Debussy) Oedipus Rex
      (Stravinsky) Pierre et le loup (Prokofiev), Le Château de Barbe-Bleue (Bartok), Ethiopiques (Zbinden).
    </p>

    <p>
      Depuis 2001 collabore, en tant qu’auteur et librettiste, à plusieurs créations musicales avec le compositeur
      suisse Thierry Besançon:
    </p>

    <ul>
      <li>Un conte symphonique: Le coq, la mouche et l’autour</li>
      <li>Un mélodrame satirique: Le Prince des Ténèbres</li>
      <li>Une facétie folklorique: Dame Helvétia</li>
      <li>Une affabulation lyrique: Landwehrland</li>
      <li>Une légende opératique: Un matin sur le Mont Chevelu</li>
      <li>Une messe ludique: Missa ludus</li>
      <li>Une cantate apocalyptique: Post tenebras… lux?</li>
      <li>Un anterequiem allégorique: Et natus es</li>
      <li>Une grande aria sabbatique: Strigoiacaria (créée au Carnegie Hall de New York)</li>
      <li>Une odyssée orphéonique: A l’horizon de Janus</li>
    </ul>

    <p><b>Minibiographie allégorique de Örs Kisfaludy</b></p>

    <p>
      Suisse hongrois, saltimbanque, conteur illusionniste, jongleur de mots, versificateur antipodiste, locuteur
      acrobate et francophile funambule.
    </p>

    <p>
      Depuis l’âge de seize ans, il arpente les scènes de théâtre, fréquente les ondes radiophoniques et laisse son
      image sur quelques pellicules télévisuelles et cinématographiques.
    </p>

    <p>
      Il prête aujourd’hui sa voix aux parties récitantes de nombreux concerts et joue de sa plume pour les compositeurs
      en mal d’histoires à mettre en musique ou de textes à faire chanter.
    </p>

    <p>
      Il sera lui-même musicien dans une autre vie; bien que son plus grand regret soit de n’avoir jamais été berger.
    </p>
  </div>
</div>
