import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
  selector: 'app-compositeurs-arrangeurs',
  templateUrl: './compositeurs-arrangeurs.component.html',
  styleUrls: ['./compositeurs-arrangeurs.component.scss'],
})
export class CompositeursArrangeursComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Les compositeurs et arrangeurs du 20ème');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.VINGTIEME);
  }
}
