<div>
  <picture class="mr-5">
    <source srcset="assets/20eme/flyer.jpg" />
    <img src="assets/20eme/flyer.jpg" alt="20eme anniversaire CPLC." />
  </picture>

  <picture>
    <source srcset="assets/20eme/pressbook.jpg" />
    <img src="assets/20eme/pressbook.jpg" alt="Press-book 20eme anniversaire CPLC." />
  </picture>

  <a id="pressbook" class="d-block mt-5 font-weight-bold text-center" href="assets/20eme/PressBook.pdf"
    >Consultez ou téléchargez le press-book complet en cliquant ici</a
  >
</div>
