import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConcertsRoutingModule } from './concerts-routing.module';
import { ProchainConcertComponent } from './prochain-concert/prochain-concert.component';
import { HistoriqueComponent } from './historique/historique.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ProchainConcertComponent, HistoriqueComponent],
  imports: [CommonModule, FormsModule, ConcertsRoutingModule, NgbModule],
})
export class ConcertsModule {}
