<h3 class="mb-4">Formulaire de commande des CDs ci-dessous</h3>

<form [formGroup]="formData" (ngSubmit)="submit()" class="w-75 mx-auto">
  <div class="form-group required">
    <label for="nomComplet">Nom complet</label>
    <input type="text" class="form-control" name="nomComplet" formControlName="nomComplet" />
  </div>
  <div class="form-group required">
    <label for="email">Email</label>
    <input type="email" class="form-control" name="email" formControlName="email" />
  </div>
  <div class="form-row">
    <div class="form-group required col-6">
      <label for="adresse">Adresse + numéro</label>
      <input type="text" class="form-control" name="adresse" formControlName="adresse" />
    </div>
    <div class="form-group required col-6">
      <label for="ville">Code postal + ville</label>
      <input type="text" class="form-control" name="ville" formControlName="ville" />
    </div>
  </div>
  <div class="form-group">
    <label for="commentaires">Commentaires éventuels</label>
    <textarea class="form-control" formControlName="commentaires" name="commentaires"></textarea>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="quantiteCdBest15_20">Quantité de CD Best of 2015 - 2020</label>
      <input type="number" class="form-control" name="quantiteCdBest15_20" formControlName="quantiteCdBest15_20" />
    </div>
    <div class="form-group col">
      <label for="quantiteCdBest10_15">Quantité de CD Best of 2010 - 2015</label>
      <input type="number" class="form-control" name="quantiteCdBest10_15" formControlName="quantiteCdBest10_15" />
    </div>

    <div class="w-100 mb-4"></div>

    <div class="form-group col">
      <label for="quantiteCdBest05_10">Quantité de CD Best of 2005 - 2010</label>
      <input type="number" class="form-control" name="quantiteCdBest05_10" formControlName="quantiteCdBest05_10" />
    </div>
    <div class="form-group col">
      <label for="quantiteCd07_08">Quantité de CD 2007 - 2008</label>
      <input type="number" class="form-control" name="quantiteCd07_08" formControlName="quantiteCd07_08" />
    </div>

    <div class="w-100 mb-4"></div>

    <div class="form-group col">
      <label for="quantiteCd06_07">Quantité de CD 2006 - 2007</label>
      <input type="number" class="form-control" name="quantiteCd06_07" formControlName="quantiteCd06_07" />
    </div>
    <div class="form-group col">
      <label for="quantiteCdPassion">Quantité de CD Passion</label>
      <input type="number" class="form-control" name="quantiteCdPassion" formControlName="quantiteCdPassion" />
    </div>
  </div>
  <button
    type="submit"
    class="btn btn-primary d-block mx-auto btn-lg font-weight-bold mt-2"
    [disabled]="!formData.valid"
  >
    Envoyer la commande
  </button>
</form>

<p *ngIf="messageSent" class="text-center font-weight-bold mt-4">
  Merci de votre commande. Elle a bien été transmise. Vous serez recontacté dès que possible.
</p>

<div class="container mt-5">
  <div class="row">
    <div class="col-4">
      <h3>Best of 2015 - 2020</h3>
      <picture>
        <source srcset="assets/cd/images/BestOf2015-2020.jpg" />
        <img src="assets/cd/images/BestOf2015-2020.jpg" alt="CD best-of 2015-2020" />
      </picture>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col">Diabolic Dance</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Diabolic Dance.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">La Voie Hermeto</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/La Voie Hermeto.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Bulgarisme</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Bulgarisme.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Askafroa's kiss</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Askafroa_s kiss.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Cri d'Espoir</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Cri d_Espoir.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Them there Eyes</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Them there Eyes.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Zerzura</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Zerzura.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Irish Legend</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Irish Legend.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Cordulie Arctique</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Cordulie Arctique.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Time Lapse</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Time Lapse.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Ce matin-là, à l'aube, dans le jardin</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Ce matin a l_aube.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Dead Line</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Dead Line.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Caravan</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Caravan.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Why not</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/BestOf2015-2020/Why not.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <h3>Best of 2010 - 2015</h3>
      <picture>
        <source srcset="assets/cd/images/BestOf2010-2015.png" />
        <img src="assets/cd/images/BestOf2010-2015.png" alt="CD best-of 2010-2015" />
      </picture>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col">Ouverture de Guillaume Tell</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/01 Ouverture de Guillaume Tell.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Le Conteur</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/02 Le Conteur.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">The Salley Gardens</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/03 The Salley Gardens.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Sixtation</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Zeste d'Orient</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/05 Zeste d'Orient.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">In the Hall of the Moutain King</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Kaddish</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Influsono</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">The Lord of the Rings</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Dunt be Blue Mon</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/10 Dunt be Blue Mon.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Pyramide</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Amazing Grace</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/12 Amazing Grace.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Venezolana</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/13 Venezolana.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">La Machine du Temps</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/14 La Machine du Temps.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Riverdance</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/15 Riverdance.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Mujer Latina</div>
        <div class="col"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <h3>Best of 2005 - 2010</h3>
      <picture>
        <source srcset="assets/cd/images/BestOf2005-2010.png" />
        <img src="assets/cd/images/BestOf2005-2010.png" alt="CD best-of 2005-2010" />
      </picture>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col">Srpska Igra</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Srpska_Igra.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Allegro Barbaro</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Sunset Road</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Sunset_Road.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Patapan</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Patapan.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Sol Del Barrio</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Sol_del_Barrio.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Une nuit dans l'antre des Moucaques</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Armando's Rhumba</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Armandos_Rhumba.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Slavonic Danse N°7</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Slavonic_Danse_N7.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Stay There</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Gaelforce</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Asmatico</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">L'enfant au tambour</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Lenfant_au_Tambour.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Les esprits des Landes</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Walk like an Egyptian</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Walk_Like_an_Egyptian.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Music from Gladiator</div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">Danse Bacchanale</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Danse_Bacchanale.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
      <div class="row">
        <div class="col">Baya Baya</div>
        <div class="col">
          <audio controls="controls">
            <source src="assets/cd/extraits/Baya_Baya.mp3" type="audio/mpeg" />
            Votre navigateur ne supporte par la lecture du fichier audio.
          </audio>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <h3>CD 2007 - 2008</h3>
      <picture>
        <source srcset="assets/cd/images/CD2007-2008.png" />
        <img src="assets/cd/images/CD2007-2008.png" alt="CD 2007-2008" />
      </picture>
      <audio controls="controls">
        <source src="assets/cd/extraits/Demo07_08.mp3" type="audio/mpeg" />
        Votre navigateur ne supporte par la lecture du fichier audio.
      </audio>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col">Prélude et élévation</div>
      </div>
      <div class="row">
        <div class="col">Aux armes</div>
      </div>
      <div class="row">
        <div class="col">Ouverture de la Grande Pâque Russe</div>
      </div>
      <div class="row">
        <div class="col">Une nuit dans l'antre des Moucaques</div>
      </div>
      <div class="row">
        <div class="col">Jaffar</div>
      </div>
      <div class="row">
        <div class="col">Les esprits des Landes</div>
      </div>
      <div class="row">
        <div class="col">Stay There</div>
      </div>
      <div class="row">
        <div class="col">Entracte</div>
      </div>
      <div class="row">
        <div class="col">Frankenstein</div>
      </div>
      <div class="row">
        <div class="col">Zorba le Grec</div>
      </div>
      <div class="row">
        <div class="col">Los Tres Golpes</div>
      </div>
      <div class="row">
        <div class="col">Sal y Pimienta</div>
      </div>
      <div class="row">
        <div class="col">Papite</div>
      </div>
      <div class="row">
        <div class="col">On Fire</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <h3>CD 2006 - 2007</h3>
      <picture>
        <source srcset="assets/cd/images/CD2006-2007.png" />
        <img src="assets/cd/images/CD2006-2007.png" alt="CD 2006-2007" />
      </picture>
      <audio controls="controls">
        <source src="assets/cd/extraits/Demo06_07.mp3" type="audio/mpeg" />
        Votre navigateur ne supporte par la lecture du fichier audio.
      </audio>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col">Une Nuit sur le Mont Chauve</div>
      </div>
      <div class="row">
        <div class="col">La Marche des 3 Oranges</div>
      </div>
      <div class="row">
        <div class="col">Allegro Barbaro</div>
      </div>
      <div class="row">
        <div class="col">Gaelforce</div>
      </div>
      <div class="row">
        <div class="col">Asmatico</div>
      </div>
      <div class="row">
        <div class="col">Sunset Road</div>
      </div>
      <div class="row">
        <div class="col">Walk Like an Egyptian</div>
      </div>
      <div class="row">
        <div class="col">Armando's Rhumba</div>
      </div>
      <div class="row">
        <div class="col">L'Enfant Tambour</div>
      </div>
      <div class="row">
        <div class="col">Barnyard Boogie</div>
      </div>
      <div class="row">
        <div class="col">Novia Bonita</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <h3>Passion</h3>
      <picture>
        <source srcset="assets/cd/images/Passion.png" />
        <img src="assets/cd/images/Passion.png" alt="CD Passion" />
      </picture>
      <audio controls="controls">
        <source src="assets/cd/extraits/Passion.mp3" type="audio/mpeg" />
        Votre navigateur ne supporte par la lecture du fichier audio.
      </audio>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col">Prélude</div>
      </div>
      <div class="row">
        <div class="col">Le souper</div>
      </div>
      <div class="row">
        <div class="col">Au jardin</div>
      </div>
      <div class="row">
        <div class="col">Judas</div>
      </div>
      <div class="row">
        <div class="col">Arrestation</div>
      </div>
      <div class="row">
        <div class="col">Condamnation</div>
      </div>
      <div class="row">
        <div class="col">Pierre renie</div>
      </div>
      <div class="row">
        <div class="col">Pilate</div>
      </div>
      <div class="row">
        <div class="col">Chargement</div>
      </div>
      <div class="row">
        <div class="col">Dépouillement</div>
      </div>
      <div class="row">
        <div class="col">Crucifixion</div>
      </div>
      <div class="row">
        <div class="col">Rien à signaler</div>
      </div>
      <div class="row">
        <div class="col">Mort de Jésus</div>
      </div>
      <div class="row">
        <div class="col">Ensemencement</div>
      </div>
      <div class="row">
        <div class="col">Final</div>
      </div>
    </div>
  </div>
</div>
