<div class="w-75 mx-auto text-justify">
  <h2>QUI SOMMES-NOUS ?</h2>

  <p>
    Il s’agit d’un ensemble de 12 à 16 musiciens triés parmi les meilleurs éléments de diverses écoles de musique ; de
    Rolle, de Crissier et des conservatoires de Lausanne et Yverdon, où plusieurs d’entre eux font leurs études en
    classe professionnelle.
  </p>

  <p>
    Ce groupe a pour but d’offrir à des percussionnistes l’occasion de jouer des œuvres où ils sont musiciens à part
    entière, du fait que les partitions sont composées ou transcrites spécialement pour eux. La percussion est ainsi
    complètement mise à l’honneur.
  </p>

  <p>
    Grâce au CPLC, le public a la possibilité de découvrir des sonorités nouvelles, notamment en ce qui concerne les
    claviers de percussion : marimba, vibraphone, xylophone, glockenspiel, jeu de cloches ainsi que d’autres accessoires
    subtils et peu connus.
  </p>

  <p>
    Le répertoire se compose d’œuvres classiques, contemporaines jusqu'au rythmes afro cubains et latinos, en passant
    par le jazz, les musiques de films ainsi que des pièces « inclassables » où la performance n’exclut pas l’humour.
  </p>

  <p>
    Le Centre de Percussions a également eu l’occasion d’interpréter à plusieurs reprises une « Passion » commandée par
    l’ Eglise évangélique réformée du canton, cette œuvre a été composée tout spécialement pour ensembles de percussions
    et récitants.
  </p>

  <p>
    Les musiciens collaborent également avec différents compositeurs et chefs d’orchestre, afin d’interpréter des œuvres
    qui nécessitent un instrumentarium particulier et important.
  </p>

  <p>
    Depuis 1995, date de sa création, chaque été les musiciens du CPLC consacrent une semaine de travail à la montagne.
  </p>

  <p>
    Ce séjour musical permet aux jeunes talents de jouer en grande formation, de développer leurs compétences musicales
    et par la même occasion de vivre une expérience communautaire et sociale des plus enrichissantes.
  </p>

  <p>
    Le répertoire est annuellement entièrement renouvelé. Afin de pouvoir assurer une moyenne de 6 à 8 concerts par
    année une « Guest Star » professionnel est invitée chaque saison afin de permettre au public de découvrir de
    nouvelles sonorités.
  </p>

  <p>
    A la direction, Claude Meynent au bénéfice d’une double formation musicale, classique et jazz, diplômé SSPM,
    professeur dans diverses écoles de musique du canton, doyen des classes de batteries et de percussions au
    conservatoire du Nord Vaudois et maître de musique à l’Ecole de la Transition (EDT).
  </p>

  <p>
    Claude Meynent joue également dans plusieurs orchestres classiques, ainsi que dans des formations de jazz et de
    variété.
  </p>

  <p>
    Si l’on souhaite une musique originale, qui étonne et qui plaît, le CPLC se fait garant d’un heureux moment musical.
  </p>
</div>
