import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
  selector: 'app-solistes',
  templateUrl: './solistes.component.html',
  styleUrls: ['./solistes.component.scss'],
})
export class SolistesComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Les solistes');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.ARTISTES);
  }
}
