import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GaleriePhotosRoutingModule } from './galerie-photos-routing.module';
import { GaleriePhotosComponent } from './galerie-photos/galerie-photos.component';
import { AlbumComponent } from './album/album.component';

@NgModule({
  declarations: [GaleriePhotosComponent, AlbumComponent],
  imports: [
    CommonModule,
    GaleriePhotosRoutingModule
  ]
})
export class GaleriePhotosModule { }
