import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HistoriqueComponent } from './historique/historique.component';
import { ProchainConcertComponent } from './prochain-concert/prochain-concert.component';

const routes: Routes = [
  { path: '', redirectTo: 'prochain-concert', pathMatch: 'full' },
  {
    path: 'prochain-concert',
    component: ProchainConcertComponent,
  },
  {
    path: 'historique',
    component: HistoriqueComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConcertsRoutingModule {}
