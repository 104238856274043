import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ContactService } from 'src/app/contact.service';

@Component({
  selector: 'app-commande-de-cd',
  templateUrl: './commande-de-cd.component.html',
  styleUrls: ['./commande-de-cd.component.scss'],
})
export class CommandeDeCDComponent implements OnInit {
  formData: UntypedFormGroup;
  formDataReset: UntypedFormGroup;
  messageSent = false;

  constructor(private titleService: Title, private formBuilder: UntypedFormBuilder, private contactService: ContactService) {
    this.titleService.setTitle('Commande de CDs');

    this.formData = this.formBuilder.group({
      nomComplet: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.compose([Validators.required, Validators.email])]),
      adresse: new UntypedFormControl('', [Validators.required]),
      ville: new UntypedFormControl('', [Validators.required]),
      commentaires: new UntypedFormControl(''),
      quantiteCdBest15_20: new UntypedFormControl(0),
      quantiteCdBest10_15: new UntypedFormControl(0),
      quantiteCdBest05_10: new UntypedFormControl(0),
      quantiteCd07_08: new UntypedFormControl(0),
      quantiteCd06_07: new UntypedFormControl(0),
      quantiteCdPassion: new UntypedFormControl(0),
    });
    this.formDataReset = this.formData.value;
  }

  ngOnInit() {}

  submit() {
    this.contactService.postMessage(this.formData.value);
    this.formData.reset(this.formDataReset);
    this.messageSent = true;
  }
}
