import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagesService } from 'src/app/images.service';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss'],
})
export class AlbumComponent implements OnInit {
  COLUMNS_NUMBER = 4;

  album: string;
  medias: {
    isVideo: boolean;
    url: string;
  }[];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private imagesService: ImagesService,
    private modalService: NgbModal
  ) {
    this.route.params.subscribe((params) => {
      this.album = params['album'];
      this.titleService.setTitle(this.album);

      this.imagesService.albumFiles$.subscribe((albumFiles) => {
        this.medias = albumFiles
          .filter((albumFile) => albumFile.album.substr(7) === this.album)
          .map((albumFile) => [
            ...albumFile.images_names
              .filter((imageUrl) => !imageUrl.endsWith('thumbnail.jpg'))
              .map((imageUrl) => ({ isVideo: false, url: imageUrl })),
            ...albumFile.videos_names.map((videoUrl) => ({ isVideo: true, url: videoUrl })),
          ])[0];
      });
    });
  }

  ngOnInit(): void {}

  open(isVideo: boolean, url: string) {
    if (isVideo) {
      const modalRef = this.modalService.open(NgbdModalVideo, { centered: true, size: 'xl' });
      modalRef.componentInstance.url = url;
    } else {
      const modalRef = this.modalService.open(NgbdModalImage, { centered: true, size: 'xl' });
      modalRef.componentInstance.url = url;
    }
  }
}

@Component({
  selector: 'ngbd-modal-content',
  template: ` <img src="https://cplc.ch/{{ url }}" /> `,
  styles: [
    ':host { height: 90vh; display: flex; }',
    'img { max-width: 100%; max-height: 100%; height: auto; width: auto; margin: auto; display: block; align-self: center; }',
  ],
})
export class NgbdModalImage {
  @Input() url: string;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <video controls>
      <source src="https://cplc.ch/{{ url }}" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  `,
  styles: [
    ':host { height: 90vh; display: flex; }',
    'video { max-width: 100%; max-height: 100%; height: auto; width: auto; margin: auto; display: block; align-self: center; }',
  ],
})
export class NgbdModalVideo {
  @Input() url: string;

  constructor(public activeModal: NgbActiveModal) {}
}
