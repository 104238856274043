import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
  selector: 'app-tchiki-duo',
  templateUrl: './tchiki-duo.component.html',
  styleUrls: ['./tchiki-duo.component.scss'],
})
export class TchikiDuoComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Le Tchiki Duo');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.VINGTIEME);
  }
}
