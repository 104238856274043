<div>
  <p>Chaque année, durant le mois de juillet, le CPLC se retrouve pour une semaine de travail intensif à Vercorin.</p>

  <p>
    Un concert à Vercorin (le vendredi soir) et deux concerts au théatre de Rolle (le samedi soir et le dimanche matin)
    clôturent le stage et lancent la nouvelle saison de concerts.
  </p>

  <h2>Recherche de musiciens</h2>

  <p>Si vous avez au moins 14 ans et que...</p>

  <ul style="list-style-type: inherit">
    <li>Votre niveau d’étude est de « moyen-avancé »,</li>
    <li>
      Vous êtes intéressé à passer une semaine à la montagne pour faire de la musique et préparer le spectacle de la
      saison,
    </li>
    <li>Vous aimez également faire un peu la fête avec une équipe sympa,</li>
    <li>Votre agenda est libre entre le samedi 7 et le dimanche 15 juillet 2018,</li>
  </ul>

  <p>
    N’hésitez pas à prendre contact avec notre directeur Claude Meynent au 079/479.70.01 ou par email :
    <a href="mailto:mey6@bluewin.ch">mey6@bluewin.ch</a>
  </p>
</div>
