import { SoutiensComponent } from './soutiens/soutiens.component';
import { CompositeursArrangeursComponent } from './compositeurs-arrangeurs/compositeurs-arrangeurs.component';
import { OrsKisfaludyComponent } from './ors-kisfaludy/ors-kisfaludy.component';
import { TchikiDuoComponent } from './tchiki-duo/tchiki-duo.component';
import { ComiteComponent } from './comite/comite.component';
import { VingtiemeAnniversaireComponent } from './vingtieme-anniversaire/vingtieme-anniversaire.component'
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VingtiemeAnniversaireRoutingModule } from './vingtieme-anniversaire-routing.module';

@NgModule({
  declarations: [VingtiemeAnniversaireComponent, ComiteComponent, TchikiDuoComponent, OrsKisfaludyComponent, CompositeursArrangeursComponent, SoutiensComponent],
  imports: [ CommonModule, VingtiemeAnniversaireRoutingModule ]
})
export class VingtiemeAnniversaireModule { }
