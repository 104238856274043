import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
  selector: 'app-vingtieme-anniversaire',
  templateUrl: './vingtieme-anniversaire.component.html',
  styleUrls: ['./vingtieme-anniversaire.component.scss'],
})
export class VingtiemeAnniversaireComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('20ème anniversaire');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.VINGTIEME);
  }
}
