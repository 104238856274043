<div class="text-center mb-5">
  <div class="btn-group" role="group">
    <input
      type="checkbox"
      id="filterCplc1"
      [value]="filterCplc"
      [checked]="filterCplc"
      (click)="filterCplc = true"
      autocomplete="off"
    />
    <label class="btn-primary" for="filterCplc1" [class.active]="filterCplc">CPLC</label>

    <input
      type="checkbox"
      id="filterCplc2"
      [value]="!filterCplc"
      [checked]="!filterCplc"
      (click)="filterCplc = false"
      autocomplete="off"
    />
    <label class="btn-primary" for="filterCplc2" [class.active]="!filterCplc">Associations amies</label>
  </div>
</div>

<div class="container">
  <div class="row">
    <ng-container *ngFor="let flyerUrl of filterCplc ? cplcFlyersUrls : amisFlyersUrls; let i = index">
      <div *ngIf="i % COLUMNS_NUMBER === 0 && i !== 0" class="w-100 mb-4"></div>
      <div class="col align-self-center">
        <a href="#">
          <img class="flyer" src="https://cplc.ch/{{ flyerUrl }}" />
        </a>
      </div>
    </ng-container>
    <ng-container
      *ngFor="
        let i of [].constructor(
          (COLUMNS_NUMBER - ((filterCplc ? cplcFlyersUrls : amisFlyersUrls)?.length % COLUMNS_NUMBER)) % COLUMNS_NUMBER
        )
      "
    >
      <div class="col"></div>
    </ng-container>
  </div>
</div>
