<div class="w-75 mx-auto">
  <div class="solistes_annee">
    <h2>2022-2023 : Valentina Rebaudo, La Reine Marco & Alessio Nebiolo</h2>

    <div>
      <picture>
        <source srcset="assets/solistes/Valentina_Rebaudo.jpg" />
        <img src="assets/solistes/Valentina_Rebaudo.jpg" alt="Valentina Rebaudo" />
      </picture>

      <p class="clearfix">Valentina Rebaudo, clarinette</p>

      <picture>
        <source srcset="assets/solistes/La_Reine_Marco.jpg" />
        <img src="assets/solistes/La_Reine_Marco.jpg" alt="La Reine Marco" />
      </picture>

      <p class="clearfix">La Reine Marco, slam</p>

      <picture>
        <source srcset="assets/solistes/Alessio_Nebiolo.jpg" />
        <img src="assets/solistes/Alessio_Nebiolo.jpg" alt="Alessio Nebiolo" />
      </picture>

      <p>Alessio Nebiolo, guitare</p>
    </div>
  </div>

  <div class="solistes_annee">
    <h2>2018-2019 : Julia Sallaberry & Marc Crofts</h2>

    <div>
      <picture>
        <source srcset="assets/solistes/Julia_Sallaberry.jpg" />
        <img src="assets/solistes/Julia_Sallaberry.jpg" alt="Julia Sallaberry" />
      </picture>

      <p>
        Julia Sallaberry a obtenu le diplôme d’enseignement de la harpe au Conservatoire de musique de la Chaux-de-Fonds
        et celui d’initiation au Conservatoire de musique de Genève, en Suisse. Elle a régulièrement suivi des cours
        avec Geneviève Chevallier, Anne Bassand, Catherine Eisenhoffer et des mastersclass auprès de Chantal Mathieu,
        Isabelle Moretti, Geneviève Létang et Marisa Robbles.
      </p>

      <p>
        Depuis plus de 15 ans, elle enseigne la harpe dans des écoles de musique et ponctuellement dans des
        conservatoires (Berne, Fribourg, Chaux-de-Fonds, Genève) et joue dans les orchestres de sa région (Nouvel
        Orchestre de Genève, Orchestre de la Madeleine, Orchestre Symphonique et Universitaire de Lausanne, Orchestre
        Symphonique Genevois, Opéra de Poche de Genève, Allgemeine Orchester Gesellschaft Thun) sous la baguette de plus
        d’une trentaine de chefs.
      </p>

      <p>
        En musique de chambre elle joue régulièrement en duo avec flûte, chant, guitare en Suisse, en Italie et en
        France mais aussi avec violon, violoncelle, etc... et accompagne des choeurs.
      </p>

      <p>
        Elle a développl l'approche de la musique auprès des enfants et a fondé sa propre structure d'ateliers musicaux
        (<a href="http://www.aufildessons.ch" alt="Ateliers musicaux au fil des sons">Au fil des sons</a>), ateliers
        aujourd’hui intégrés dans la formation d’adultes de l’éducation de la petite enfance (HES Genève).
      </p>

      <p>
        Julia Sallaberry a partagé avec Nathalie Chatelain (harpiste) « Merlin » créé en 2007 pour les « Journées de la
        harpes » à Arles, France.
      </p>

      <p>
        Un spectacle de clowns « D'une corde à l'autre », avec Véronique Navarre (chant) – duo animé voguant de la
        chanson française à un répertoire contemporain et classique – est né en 2008.
      </p>

      <p>
        Elle a été invitée en 2010 à être la première harpe du « Thailand Philarmonic Orchestra » et professeur de harpe
        à « Mahidol », prestigieuse université gouvernementale de Thaïlande.<br />
        En 2012, elle a repris pendant six mois la classe de harpe de la Chaux-de-Fonds (M. Pierrehumbert). En 2013,
        elle a travaillé comme harpiste au Plaza Athenee Royal Le Meridien à Bangkok.<br />
        En 2014, de retour en Suisse, elle continue de développer ses activités d'ateliers musicaux, enseigne la harpe
        et l'éveil musical dans divers structures et conservatoires. Elle se forme pour amener la musique là où la
        communication est plus difficile (auprès d’enfants malades ou de personnes atteintes de démence). Depuis 2015,
        une quinzaine de concerts en duo (Carine Séchaye, mezzo ou Benoît Volkart, baryton) ou en orchestre agrémentent
        ses années musicales.
      </p>
    </div>

    <div>
      <picture>
        <source srcset="assets/solistes/Marc_Crofts.jpg" />
        <img src="assets/solistes/Marc_Crofts.jpg" alt="Marc Crofts" />
      </picture>

      <p>
        Né à Genève en 1989, Marc Crofts commence à jouer du violon très jeune. En 2000, après différents professeurs,
        il rencontre Florin Moldoveanu (OSR) avec qui il étudiera jusqu'en 2008. En 2017 obtention d'un diplôme de
        Master en violon jazz à la HEMu de Lausanne avec Christine Sörensen et Vinz Vonlanthen. Il a également été suivi
        par Mario Forte et eu l'occasion de participer aux ateliers de Mathieu Michel, Bänz Oester, Emil Spanyi, Robert
        Bonisolo, Jeff Beaud, Francis Coletta, Philip Henzi, Thomas Dobler, etc. En outre, il a assisté aux
        masterclasses de grandes personnalités du jazz comme Charles Loyd, Dave Liebman, Lee Konnitz, Marc Ducret,
        Malcolm Braff, John Abercrombie ou encore Bob Mintzer, ainsi qu'aux masterclasses des meilleurs violonistes de
        jazz, Jean-Luc Ponty, Didier Lockwood, Johan Renard, Mathias Levy, Oriol Sana, Michael Gustorff, etc...
      </p>

      <p>
        Au fil de ses voyages à l'est, il a eu l'occasion de travailler avec de nombreux musiciens, comme Kovács Ferenc
        (Kalman Balogh & the Gipsy Cimbalom Band), Szirtes Edina Mókus (Fabula Rasa), Lefteris Tsikouridis (Pliri Ntaxi,
        Rebeteke), Nedim Nalbantoglu (Balkan Messengers), Aydin Varol (TRT), Mübin Dünen (Kara Günes), Izzet Kizil
        (Furtuna), Martin Lubenov, Lidian Slavchev, et bien d'autres... En été 2013 il part en tournée avec le Barcelona
        Gipsy Klezmer Orchestra (BGKO) en Espagne, Italie et Slovénie, où ils collaborent notamment avec le chanteur
        Vlado Kreslin. Il a également participé à une création inédite avec Pascal Auberson en 2016.
      </p>

      <p>
        Depuis de nombreuses années il se produit avec le groupe festif Gypson 5ive, basé à Genève, et depuis 2015 avec
        le projet de klezmer balkanique, Gilgul. On peut également l'entendre jouer du swing et du jazz manouche avec
        diverses formations, notamment le collectif Swing High, ou du flamenco au sein du Yolanda Almodovar Quintet ou
        encore avec Etienne Mayerat. En 2016 Marc lance Hot Temper, trio plus intimiste ou ses compositions sont à
        l'honneur, et en 2017 le Marc Crofts trio.
      </p>
    </div>
  </div>

  <div class="solistes_annee">
    <h2>2017-2018 : Benoît Viredaz & Bernard Schwenter</h2>

    <div>
      <picture>
        <source srcset="assets/solistes/Bernard_Schwenter.jpg" />
        <img src="assets/solistes/Bernard_Schwenter.jpg" alt="Bernard Schwenter" />
      </picture>

      <p>
        Bernard Schwenter, musicien parmi les baby-boomers, a enseigné jusqu’en 2015 au Conservatoire et à la HEP de
        Fribourg.
      </p>

      <p>
        Il est un des rares banjo-solo européens ; son activité de concertiste s’étend le long des dix-huit fuseaux
        horaires qui séparent les marais de la Nouvelle-Orléans des volcans du Kamchatka. Il a également composé la
        musique de nombreux spectacles, essentiellement pour des troupes de théâtre et de cirque.
      </p>
    </div>

    <div>
      <picture>
        <source srcset="assets/solistes/Benoit_Viredaz.jpg" />
        <img src="assets/solistes/Benoit_Viredaz.jpg" alt="Benoit Viredaz" />
      </picture>

      <p>
        Quant à Benoît Viredaz, concertiste de métier depuis 1987, il est porté par une grande curiosité à mettre ses
        talents au service de différents styles, des plus traditionnels aux tentatives les plus hardies, dans toute
        l'Europe, au Proche Orient ainsi qu'en Chine.
      </p>

      <p>
        Toujours au service des musiques qui l'emploient, il joue avec une sonorité ronde et chaleureuse alliée à une
        amplitude de plus de 5 octave. Sa maîtrise dynamique ouvrant sur des nuances d'une subtilité inattendue lui
        permet de jouer sur toutes les cordes de sa grande sensibilité.
      </p>
    </div>
  </div>

  <div class="solistes_annee">
    <h2>2016-2017 : Doolin</h2>

    <picture>
      <source srcset="assets/solistes/Doolin.jpg" />
      <img src="assets/solistes/Doolin.jpg" alt="Photo d'ensemble de Doolin." />
    </picture>

    <p>Les origines du groupe Doolin...</p>
    <p>
      Calfeutrés dans l’obscurité protectrice, nous nous rendions chaque quinzaine hors de nos frontières afin d’y
      retrouver d’autres adeptes aux goûts musicaux aussi sombres que les nôtres. En effet, et afin d’échapper à de
      terribles représailles de la part de nos contemporains à l’esprit trop étriqué, nous nous voyions contraints, deux
      fois par cycle lunaire, à un bref exil en terre gauloise. Car c’est le hameau de Ferney-Voltaire qui, en ces temps
      lointains (1999), abritait les réunions occultes de ces musiciens aux pratiques inavouables!
    </p>
  </div>

  <div class="solistes_annee">
    <h2>2014-2015 : Aurore Dumas</h2>

    <picture>
      <source srcset="assets/solistes/Aurore_Dumas.jpg" />
      <img src="assets/solistes/Aurore_Dumas.jpg" alt="Portrait d'Aurore Dumas." />
    </picture>

    <p>
      Aurore Dumas a étudié la harpe au Conservatoire de Musique de Fribourg avec Geneviève Chevallier, où elle obtient
      en juin 2007 son diplôme d'enseignement avec mention très bien. En complément à ses études de harpe, elle acquiert
      en juin 2008 son diplôme de maître de musique à la Haute École de Musique de Genève.
    </p>

    <p>
      Elle s'investit en tant que musicienne dans son domaine de prédilection, la musique de chambre, sous diverses
      formations instrumentales et de styles variés. C'est ainsi qu'elle a fondé avec son amie clarinettiste Lea
      Sangiorgio le duo Inaka – duo clarinette et harpe consacré et spécialisé dans le répertoire de musique
      contemporaine. Elle collabore également avec plusieurs orchestres et ensembles instrumentaux de Suisse romande.
    </p>

    <p>
      Parallèlement à son activité d'instrumentiste, elle est depuis septembre 2008 professeur de harpe au Conservatoire
      de Musique du Nord Vaudois à Yverdon et au Conservatoire Neuchâtelois depuis novembre 2009.
    </p>
  </div>

  <div class="solistes_annee">
    <h2>2013-2014 : Monique Dupuis Léopoldoff</h2>

    <picture>
      <source srcset="assets/solistes/Monique_Dupuis_Leopoldoff.jpg" />
      <img src="assets/solistes/Monique_Dupuis_Leopoldoff.jpg" alt="Portrait de Monique Dupuis Léopoldoff." />
    </picture>

    <p>
      Après une maturité artistique au collège Voltaire (1992), Monique Dupuis Léopoldoff entre au Conservatoire
      Supérieur de Musique de Genève dans la classe de M. Jean- Claude Hermenjat. En 1996, elle obtient les diplômes de
      maître de musique, flûte et solfège.
    </p>

    <p>
      Lauréate du concours des Jeunesses Musicales Suisse à Riddes en 1997, elle clôt sa formation de flûtiste avec un
      premier prix de virtuosité (1998).
    </p>

    <p>
      Elle entame ensuite des études de direction au conservatoire de Lausanne, dans la classe de Pascal Favre et
      obtient un diplôme de direction de fanfare et harmonie (2003).
    </p>

    <p>
      Elle partage sa vie professionnelle entre les concerts, l’enseignement de la flûte et du solfège, la direction, et
      un temps consacré à l’écriture autour de transcriptions, d’arrangements ou encore de compositions.<br />
      On la retrouve fréquemment sur scène en formation musique de chambre, plus particulièrement au sein de l’Ensemble
      Tétraflûtes depuis sa fondation (1994), avec lequel elle a enregistré deux CDs, mais aussi en tant que soliste ou
      musicienne d’orchestre.
    </p>

    <p>
      Sous-directrice de l’Ecole de Musique de Nyon et directrice de sa petite harmonie, elle a été nommée à la tête de
      l'harmonie « La Lyre de Begnins (VD) » en septembre 2010 après avoir dirigé pendant 7 ans l’harmonie « La Lyre de
      Chêne-Bougeries (GE).» Elle est aussi professeur de flûte traversière à l’école de musique de Rolle et Environ.
    </p>
  </div>

  <div class="solistes_annee">
    <h2>2012-2013 : Serge Schlaeppi</h2>

    <picture>
      <source srcset="assets/solistes/Serge_Schlaeppi.jpg" />
      <img src="assets/solistes/Serge_Schlaeppi.jpg" alt="Portrait de Serge Schlaeppi." />
    </picture>

    <p>
      Serge Schlaeppi, né il y a plus d’un demi-siècle, fait partie de ces musiciens dont la personnalité et
      l’originalité du jeu enrichissent les couleurs orchestrales des groupes qu’il fréquente.
    </p>

    <p>
      Son premier instrument fut un harmonica, qu’il maîtrisa très tôt et sans difficulté. Bien que paresseux de nature,
      c’est sa curiosité pour la musique et les instruments qui le fit avancer.
    </p>

    <p>
      Enfin, il choisit de travailler le trombone au conservatoire de Genève et de participer à divers séminaires donnés
      par des trombonistes de renom. Ce qui ne l’empêche pas de groover sur une guitare basse au sein de diverses
      formations.
    </p>

    <p>
      Heureusement, aujourd’hui cet iconoclaste travaille, ce qui lui permet de s’exprimer dans les domaines artistiques
      les plus variés.
    </p>
  </div>

  <div class="solistes_annee">
    <h2>2011-2012 : Anne Ramoni</h2>

    <picture>
      <source srcset="assets/solistes/Anne_Ramoni.jpg" />
      <img src="assets/solistes/Anne_Ramoni.jpg" alt="Portrait d'Anne Ramoni." />
    </picture>

    <p>
      Après avoir travaillé sa voix chez divers professeurs de Suisse Romande (dont Simone Mercier à Lausanne et Tiny
      Westendorp à Fribourg), Anne Ramoni, soprano, accomplit ses études professionnelles de chant au Conservatoire de
      Lausanne chez Jane Mayfield, puis obtient sa Virtuosité à Genève chez Maria Diaconù. Elle se perfectionne
      également auprès d'Alfred Tomatis à Paris, d'Hugues Cuénod en Suisse et d'Anthony Rolfe Johnson à Londres. Depuis,
      et selon le répertoire qu'elle souhaite défendre, elle poursuit sa formation continue chez des musiciens confirmés
      comme Brigitte Balleys, François Margot, ou encore Denise Bregnard pour la broadway voice.
    </p>

    <p>
      Ses activitéé professionnelles se partagent actuellement entre concerts et pédagogie. Son répertoire, volontiers
      orienté vers l’oratorio, le Lied et la Mélodie française, lui donne régulièrement l'occasion de chanter en Suisse
      et à l'étranger. Son intérêt pour la musique contemporaine lui a également permis de participer à plusieurs
      créations d’oeuvres de compositeurs suisses, comme "Le Signe de Sarepta" de D. Gesseney-Rapo, enregistré en 1999.
    </p>

    <p>
      C'est avec le quatuor Die Nachtigall qu'elle forme l'ensemble Nachtigall et qu'elle travaille le plus
      régulièrement. Leur répertoire "maison" est composé de coups de coeurs et puise ses sources dans les airs à
      vocalises des XIXe et XXe siècles.
    </p>

    <p>
      Dans le genre du récital avec piano, c'est avec Dario Alasia qu'elle élabore ses programmes. Après Hugo Wolf et
      les mélodistes français, ce sont R. Strauss, A. Berg et O. Respighi qui seront à l'honneur dans leurs prochains
      récitals.
    </p>

    <p>
      Enfin, elle prépare un spectacle d'airs de comédies musicales avec l'ensemble vocal Côte et Choeur dans des
      arrangements et sous la direction de Dominique Gesseney.
    </p>

    <p>
      Passionnée de technique vocale, elle enseigne tant en privé à Gland qu'au sein d’associations comme l’AVDC
      (Association Vaudoise des Directeurs de Choeurs), la SSPM (Société Suisse de Pédagogie Musicale) ou l’APCS
      (Association des Professeurs de Chant de Suisse). Elle a secondé René Falquet à la direction du Grand Atelier d’<i
        >A Coeur Joie</i
      >, ainsi que Pascal Mayer au sein du choeur Pro Arte à Lausanne. Son expérience acquise aux côtés de
      Jean-Christophe Aubert à la tête du Choeur Universitaire de Lausanne durant 11 ans lui vaut également d'intervenir
      dans la formation des futurs chefs de choeur de l'AVDC.
    </p>

    <p>
      Régulièrement invitée à s'exprimer sur les ondes, on peut l’entendre dans des émissions comme
      <i>Radio Paradiso</i> (RSR 1) ou <i>Disques en Lice</i> (Espace 2).
    </p>
  </div>

  <div class="solistes_annee">
    <h2>2010-2011 : Alexandre Cellier</h2>

    <picture>
      <source srcset="assets/solistes/Alexandre_Cellier.jpg" />
      <img src="assets/solistes/Alexandre_Cellier.jpg" alt="Portrait d'Alexandre Cellier." />
    </picture>

    <p>
      A quatorze ans, il se passionne pour le piano Jazz et l'improvisation grâce à François Lindemann et poursuit ses
      études au Conservatoire de Lausanne avec Christian Favre. Depuis 1982, il joue dans diverses formations et compose
      pour la danse, le théâtre, les contes et le cinéma.
    </p>

    <p>
      Tzigane dans l’âme, il entreprend plusieurs voyages en Afrique, au Brésil, aux Caraïbes, en Louisiane et en Europe
      de l'Est. A chaque fois, la musique lui permet de faire des rencontres magiques qu'il est impatient de faire
      partager. De sa passion est né un livre-CD sur les percussions et la vie musicale du Burkina Faso. De Trinidad, il
      rapporte un steel pan qu’il va intégrer dans des compositions et des improvisations qui fascineront le public de
      Rio de Janeiro.
    </p>

    <p>
      Compositeur, interprète, Alexandre Cellier se produit souvent avec un autre multi-instrumentiste Jean Duperrex.
      Leur complicité se réalise dans
      <a href="http://www.alexcellier.ch/Duocd/CD___DVD.html" title="CD et DVD d'Alexandre Cellier"
        >deux CD "Voyage au bout des notes" et "Opus 2004"</a
      >
      Live à L’Esprit Frappeur constitué uniquement de compositions personnelles.
    </p>

    <p>
      Passionné de rencontres musicales, il développe différents projets avec d’autres musiciens comme Ion Miu, Antoine
      Auberson, Annick Rody, Maria de la Paz, William Fierro, Aurélie Tropez, Evan Christopher, André- Daniel Meylan,
      Olivier Grosclaude, Semira Azad, Cyril Regamey... Depuis 20 ans, il vit entièrement de ses concerts et des
      animations. Sa manie: transformer des objets de la vie de tous les jours en instruments de musique... méfiez-vous!
    </p>
  </div>
</div>
