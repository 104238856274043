import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CPLCRoutingModule } from './cplc-routing.module';
import { DirecteurComponent } from './directeur/directeur.component';
import { HistoriqueComponent } from './historique/historique.component';
import { StageAnnuelComponent } from './stage-annuel/stage-annuel.component';

@NgModule({
  declarations: [DirecteurComponent, HistoriqueComponent, StageAnnuelComponent],
  imports: [
    CommonModule,
    CPLCRoutingModule
  ]
})
export class CPLCModule { }
