import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConcertsService {
  public concertsFlyers$: Observable<ConcertsFlyers>;

  constructor(private http: HttpClient) {
    this.concertsFlyers$ = this.http.get<ConcertsFlyers>('https://cplc.ch/backend/concerts_flyers.php');
  }
}

export interface ConcertsFlyers {
  cplc: string[];
  amis: string[];
}
