<div>
  <ul>
    <li>Muriel Sommer - Ecole de Musique La Clé d'Argent (Renens)</li>
    <li>Pedro Bispo Alves - Haute Ecole de Musique de Lausanne</li>
    <li>Axel Maurer - Haute Ecole de Musique de Lausanne</li>
    <li>Thomas Gubler - Haute Ecole de Musique de Lausanne</li>
    <li>Diego Lopez - Ecole de musique de Crissier</li>
    <li>Alexis Ottet - Haute Ecole de Musique de Berne</li>
    <li>Nicolas Wichoud - Ecole de Musique d'Epalinges</li>
    <li>Franck Widmann - Conservatoire de Musique du Nord Vaudois</li>
    <li>Louis Thomson - Ecole de musique Multisite Etoy</li>
    <li>Camille Cossy - Haute Ecole de Musique de Lausanne</li>
    <li>Noé Staub - Ecole de musique Multisite Granges-près-Marnand</li>
    <li>Kilian Brinkley - Conservatoire de Lausanne</li>
    <li>Havanna Menth</li>
    <li>Lionel Schmied</li>
  </ul>

  <p><b>Supplémentaires</b></p>
  <ul>
    <li>Youri Rosset - Haute Ecole de Musique de Lausanne</li>
  </ul>

  <p><b>Basse électrique</b></p>
  <ul>
    <li>Livio Arcuri</li>
  </ul>
</div>
