import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  public albumFiles$: Observable<AlbumFiles[]>;

  constructor(private http: HttpClient) {
    this.albumFiles$ = this.http.get<AlbumFiles[]>('https://cplc.ch/backend/gallery_files.php');
  }
}

export interface AlbumFiles {
  album: string;
  images_names: string[];
  videos_names: string[];
}
