import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendrierComponent } from './calendrier/calendrier.component';
import { TelechargementsComponent } from './telechargements/telechargements.component';

const routes: Routes = [
  { path: '', redirectTo: 'calendrier', pathMatch: 'full' },
  {
    path: 'calendrier',
    component: CalendrierComponent,
  },
  {
    path: 'téléchargements',
    component: TelechargementsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccesMusiciensRoutingModule {}
