<div class="w-75 text-center mx-auto">
  <picture class="d-block w-50 mx-auto mb-5">
    <source srcset="assets/ClaudeMeynent.jpg" />
    <img src="assets/ClaudeMeynent.jpg" alt="Portrait de Claude Meynent." />
  </picture>

  <div class="text-justify">
    <p>
      Né en ce fameux hiver 1956, le 24ème jour de ce mois plutôt glacé, Claude senti très tôt le besoin d’être en
      contact avec des matériaux chaleureux tel que peaux, bois, voir différents métaux.
    </p>

    <p>
      Dès son plus jeune âge, il décide de faire résonner son tambour dans les rues de Rolle et ainsi donner le coup
      d’envoi à cette formidable aventure musicale.
    </p>

    <p>
      Après des études au conservatoire de Genève ainsi qu’à l’école de jazz et de musique actuelle (EJMA) à Lausanne,
      dernièrement une formation pédagogique à la SSPM, sa passion pour la percussion lui permet aujourd’hui d’enseigner
      et de transmettre son savoir et ses idées au sein de plusieurs conservatoires et écoles de musiques de Suisse
      Romande.
    </p>

    <p>Il enseigne également à l’Ecole de la Transition (EDT).</p>

    <p>
      Musicien créateur, participe à de nombreux projets, avec chœurs et orchestres, collabore avec différents chefs et
      metteurs en scène.<br />
      Fondateur et Directeur du Centre de Percussions de la Côte (CPLC), cet éternel assoiffé d’idées nouvelles et de
      défis aime particulièrement jouer, composer, organiser, créer ; que se soit en formation classique, jazz ou
      variété.
    </p>
  </div>
</div>
