import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
  selector: 'app-stage-annuel',
  templateUrl: './stage-annuel.component.html',
  styleUrls: ['./stage-annuel.component.scss'],
})
export class StageAnnuelComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Stage annuel');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.CPLC);
  }
}
