<div class="w-75 mx-auto text-center">
  <video class="w-75 mb-4" controls>
    <source src="assets/CPLC23.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>

  <div>
    <p>
      Le Centre de Percussions de la Côte (CPLC) est un ensemble basé à Rolle de 12 à 16 musiciens triés parmi les
      meilleurs éléments de diverses écoles de musique du canton de Vaud.
    </p>

    <p>Aussi bien batterie que marimba, xylophone, vibraphone et timbales y ont leur place.</p>

    <p>Il est dirigé par Claude Meynent depuis sa création il y a {{ cplcAge }} ans.</p>
  </div>

  <div>
    <p>
      La saison 2024-2025 sera comme à notre habitude l'occation de nous produire à Vercorin et au Casino-Théâtre de
      Rolle en juillet à l'issue de notre stage d'été. Puis, nous jouerons à nouveau le programme en février à Chéserex.
      Vous trouverez plus d'informations sur
      <a [routerLink]="['/concerts/prochain-concert']" alt="Prochain concert">notre page dédiée</a>.
    </p>
  </div>
</div>

<div class="d-flex mx-auto mt-4 align-items-center">
  <picture class="d-block mx-auto w-50">
    <source srcset="{{ 'assets/prochain-concert.jpg?preventCaching=' + buildHash }}" />
    <img
      src="{{ 'assets/prochain-concert.jpg?preventCaching=' + buildHash }}"
      alt="Flyer du prochain concert du CPLC"
      width="400px"
    />
  </picture>
  <video class="w-50 pl-5" controls *ngIf="false">
    <source src="assets/videos/Stop_Single_Use_2021.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
