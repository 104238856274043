import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComiteComponent } from './comite/comite.component';
import { CompositeursArrangeursComponent } from './compositeurs-arrangeurs/compositeurs-arrangeurs.component';
import { OrsKisfaludyComponent } from './ors-kisfaludy/ors-kisfaludy.component';
import { SoutiensComponent } from './soutiens/soutiens.component';
import { TchikiDuoComponent } from './tchiki-duo/tchiki-duo.component';
import { VingtiemeAnniversaireComponent } from './vingtieme-anniversaire/vingtieme-anniversaire.component';

const routes: Routes = [
  { path: '', redirectTo: '20eme', pathMatch: 'full' },
  {
    path: '20eme',
    component: VingtiemeAnniversaireComponent,
  },
  {
    path: 'comite',
    component: ComiteComponent,
  },
  {
    path: 'tchiki-duo',
    component: TchikiDuoComponent,
  },
  {
    path: 'ors-kisfaludy',
    component: OrsKisfaludyComponent,
  },
  {
    path: 'compositeurs-arrangeurs',
    component: CompositeursArrangeursComponent,
  },
  {
    path: 'soutiens',
    component: SoutiensComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VingtiemeAnniversaireRoutingModule {}
