import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
  selector: 'app-ors-kisfaludy',
  templateUrl: './ors-kisfaludy.component.html',
  styleUrls: ['./ors-kisfaludy.component.scss'],
})
export class OrsKisfaludyComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Örs Kisfaludy');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.VINGTIEME);
  }
}
