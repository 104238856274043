import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
  selector: 'app-soutiens',
  templateUrl: './soutiens.component.html',
  styleUrls: ['./soutiens.component.scss'],
})
export class SoutiensComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Les soutiens');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.VINGTIEME);
  }
}
