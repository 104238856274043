<div>
  <div class="comp_arr">
    <h2>Monique Dupuis Léopoldoff</h2>

    <div>
      <picture>
        <source srcset="assets/20eme/Monique_Dupuis_Leopoldoff.jpg" />
        <img src="assets/20eme/Monique_Dupuis_Leopoldoff.jpg" alt="Portrait de Monique Dupuis Léopoldoff." />
      </picture>

      <p>
        Après une maturité artistique au collège Voltaire (1992), Monique Dupuis Léopoldoff entre au Conservatoire
        Supérieur de Musique de Genève dans la classe de M. Jean- Claude Hermenjat. En 1996, elle obtient les diplômes
        de maître de musique, flûte et solfège.
      </p>

      <p>
        Lauréate du concours des Jeunesses Musicales Suisse à Riddes en 1997, elle clôt sa formation de flûtiste avec un
        premier prix de virtuosité (1998).
      </p>

      <p>
        Elle entame ensuite des études de direction au conservatoire de Lausanne, dans la classe de Pascal Favre et
        obtient un diplôme de direction de fanfare et harmonie (2003).
      </p>

      <p>
        Elle partage sa vie professionnelle entre les concerts, l’enseignement de la flûte et du solfège, la direction,
        et un temps consacré à l’écriture autour de transcriptions, d’arrangements ou encore de compositions.<br />
        On la retrouve fréquemment sur scène en formation musique de chambre, plus particulièrement au sein de
        l’Ensemble Tétraflûtes depuis sa fondation (1994), avec lequel elle a enregistré deux CDs, mais aussi en tant
        que soliste ou musicienne d’orchestre.
      </p>

      <p>
        Sous-directrice de l’Ecole de Musique de Nyon et directrice de sa petite harmonie, elle a été nommée à la tête
        de l'harmonie « La Lyre de Begnins (VD) » en septembre 2010 après avoir dirigé pendant 7 ans l’harmonie « La
        Lyre de Chêne-Bougeries (GE).» Elle est aussi professeur de flûte traversière à l’école de musique de Rolle et
        Environ.
      </p>
    </div>
  </div>

  <div class="comp_arr">
    <h2>Blaise Mettraux</h2>

    <div>
      <picture>
        <source srcset="assets/20eme/BlaiseMettraux.jpg" />
        <img src="assets/20eme/BlaiseMettraux.jpg" alt="Portrait de Blaise Mettraux." />
      </picture>

      <p>
        Blaise Mettraux est né en 1962 à Lausanne. Après des études de piano classique au Conservatoire de Lausanne, il
        poursuit ses études au Conservatoire de jazz de Fribourg. Il participe à de nombreux concerts dans toute
        l'Europe, à la création de plusieurs albums dans le domaine de la musique pop avec des groupes comme Cosa
        Nostra, Taboo ou Sens-Unik. Il travaille aussi en tant qu'accompagnateur pour divers chanteurs et chanteuses
        avec lesquels il réalise plusieurs albums. Il travaille aussi avec des DJ et sort plusieurs albums avec Mr Mike,
        Willow et Bruce Baps pour lequel il compose toute la musique de l'album NoFace.
      </p>

      <p>
        Il enseigne dans les écoles secondaires depuis 1987 et a composé en 1994 la musique de Mars, spectacle pour
        enfants. En 1993, il reprend ses études au Conservatoire de Lausanne pour l'obtention d'un brevet de maître de
        musique.
      </p>

      <p>
        Dès 1995, ses activités de percussioniste au sein de l'Orchestre Symphonique Universitaire de Lausanne (OSUL) et
        de l'Orchestre Symphonique de Genève (OSG) l'amène à s'intéresser de près à la composition musicale pour choeur
        et pour orchestre. Il compose une Messe en ré mineur pour choeur, alto solo et petit ensemble qui sera créée en
        1996.
      </p>

      <p>
        1997 sera l'année de deux créations. D'une part la Messe en ré mineur réarrangée pour choeur, alto solo et
        orchestre symphonique, qui sera créée en mai à la cathédrale de Lausanne par l'Orchestre Symphonique
        Universitaire (OSUL) et le Choeur des Gymnases Lausannois, et d'autre part Chemin Faisant, promenade pour petit
        ensemble, choeur mixte, choeur d'homme et récitant, commande de la Société Cantonale des Chanteurs Vaudois
        (SCCV) et créée à Echallens lors des Rencontres Chorales 1997. Dès lors des commandes vont s'enchaîner.
      </p>
    </div>
  </div>

  <div class="comp_arr">
    <h2>Sylvain Andrey</h2>

    <picture>
      <source srcset="assets/20eme/SylvainAndrey.jpg" />
      <img src="assets/20eme/SylvainAndrey.jpg" alt="Portrait de Sylvain Andrey." />
    </picture>

    <p>
      Sylvain Andrey est né le 25 novembre 1990 à Lausanne. Dès l'âge de 7 ans, il commence la percussion avec Frederic
      Rod à l'Ecole de musique de Lausanne puis à l'école de musique d'Epalinges où il y obtiendra un certificat avec
      mention "excellent".
    </p>

    <p>
      En 2008, il entre au Conservatoire de Lausanne où il y décroche son certificat AVCEM et débute en 2010 ses études
      professionnelles dans la classe de percussions de Stéphane Borel, Jacques Hostettler et Nicolas Suter à la Haute
      Ecole de Musique de Lausanne (HEMU).
    </p>

    <p>
      En 2013, il y obtient son Bachelor couronné par un prix d'analyse décerné par l'HEMU. Son parcours musical est
      jalonné de nombreux résultats dans différents concours comme champion vaudois de percussion en 2006 et 2007 ainsi
      que premier de sa catégorie au concours suisse de l'OSEW en marimba et timbales en 2006 et 2008.
    </p>

    <p>
      Parallèlement à ses études, Sylvain Andrey a étudié la direction d'orchestre à vents dans la classe de Pascal
      Favre au Conservatoire de Lausanne et a obtenu son certificat en 2012.
    </p>

    <p>
      Actif tant à son instrument que dans la composition, Sylvain Andrey a déjà écrit un certain nombre d'oeuvre pour
      différents effectifs. Ainsi, sa pièce pour harmonie "Le Mythe du K" est créée en 2008 avec le récitant Daniel
      Rausis et, en 2010, sa première oeuvre symphonique "La symphonie de l'Ailleurs" interprétée par l'Orchestre des
      Collèges et Gymnases Lausannois (OCGL) est non seulement créée mais également programmée en ouverture des Ecolades
      de la Chaux-de-fonds.
    </p>

    <p>
      Le Centre de Percussions de la Côte dirigé par Claude Meynent (CPLC) lui a commandé un grand nombre de pièces,
      toutes enregistrées sur CD. Actuellement, Sylvain Andrey est timbalier solo de l'Orchestre Symphonique Genevois
      (OSG) et participe à divers concerts avec l'Orchestre de Chambre de Lausanne (OCL) ou encore en duo avec Paula
      Chico Martinez (Chronos Duo).
    </p>

    <p>
      Actuellement en cours d'obtention de son Master d'enseignement instrumental à la Haute Ecole de Musique de
      Lausanne, il enseigne la batterie et la percussion à l'école de musique de Granges-Marnand.
    </p>
  </div>

  <div class="comp_arr">
    <h2>Sébastien Aegerter</h2>

    <picture>
      <source srcset="assets/20eme/SebastienAegerter.jpg" />
      <img src="assets/20eme/SebastienAegerter.jpg" alt="Portrait de Sébastien Aegerter." />
    </picture>

    <p>
      Né le 22 août 1983 à Lausanne, Sébastien est passionné par la musique depuis tout petit. Il commence son
      initiation musicale par les cours de percussion et de batterie à l’Ecole de Musique de Crissier, puis il ne tarde
      pas à entrer à la Haute Ecole de Musique de Lausanne où il obtient son master de pédagogie en 2009 ainsi que son
      master de performance en 2011 dans la classe de M. Stéphane Borel.
    </p>

    <p>
      Il profite d'une année d'échange pour aller se perfectionner à l'Académie Royale du Danemark dans la classe de
      Gert Mortensen, Johan Bridger et Morten Friis (Safri Duo). Il a aussi travaillé avec des compositeurs romands dont
      Jean Balissat, Jean-François Zbinden, Thierry Besançon, Guy-François Leuenberger et Valentin Villard.
    </p>

    <p>
      Depuis 2004, Sébastien collabore à de nombreux projets tous azimuts: du groupe de rock progressif aux orchestres
      symphoniques, en passant par l’accompagnement d’ensembles pluridisciplinaires et d’artistes différents (Compagnie
      3art3, Lausanne Marimba Ensemble, Keiko Abe, Fritz Hauser, Ecole Rudra-Béjart, Tchiki Duo, Platform 2, Convulsif
      Big Band, Collision).
    </p>

    <p>
      En 2008 il obtient le deuxième prix du concours national d’exécution musicale à Riddes. En 2011 il se fait repérer
      par Pedro Carneiro et le rencontre au Portugal pour une série de cours et workshops.
    </p>

    <p>
      Il crée en juin de la même année sa propre composition: "la disparition", pièce pour marimba et chanteur-comédien
      sur un texte de Georges Pérec avec la participation de Daniel Hellmann. Il crée et compose d'autres pièces pour
      percussion et électronique (Lhotse 19.05.1956 et G-Méca). Toujours la même année il crée avec la collaboration
      d'Etienne Studer "Espèce d'espace", forme d'art installation dans une cave viticole neuchâteloise.
    </p>

    <p>
      En 2012, il forme avec Nicolas Crausaz le duo Platform2 qui consiste en des performances visuelles et musicales
      sur mesure en tout lieu. Il participe aussi à la création du spectacle "K" à Zürich pour le compte de la compagnie
      3art3.
    </p>

    <p>
      Il enseigne actuellement à l'école de musique de Crissier ainsi qu'à l'école de musique de la ville de Monthey et
      est régulièrement engagé en tant qu'expert pour différents concours et examens.
    </p>

    <p>
      Site internet :
      <a href="http://www.sebastien-aegerter.ch" title="Site internet de Sébastien Aegerter" target="_blank"
        >www.sebastien-aegerter.ch</a
      >
    </p>
  </div>

  <div class="comp_arr">
    <h2>Julien Mégroz</h2>

    <picture>
      <source srcset="assets/20eme/JulienMegroz.jpg" />
      <img src="assets/20eme/JulienMegroz.jpg" alt="Portrait de Julien Mégroz." />
    </picture>

    <p>
      Julien Mégroz (1986* CH) est percussionniste, improvisateur et compositeur, très engagé dans le milieu de l’art
      contemporain.
    </p>

    <p>
      Premier prix de marimba au Concours National d’Interprétation Musicale de Riddes en 2008, Bachelor en percussion
      en 2009 à l’HEMU de Lausanne puis Master de Concert avec félicitations du jury en 2011, comprenant une année
      ERASMUS à la Royal Danish Academy of Music de Copenhague. Master de musique de chambre contemporaine à la
      Hochschule für Musik à Bâle en 2013 et CAS en improvisation libre en 2014. Finaliste, en duo avec l’accordéoniste
      Sofia Ahjoniemi, au concours Nicati à Bern en 2013.
    </p>

    <p>
      Julien se produit régulièrement en concert en Suisse et dans le monde, en solo ou au sein de divers ensembles. En
      temps que compositeur, ses œuvres récentes sont : « La légende du pont du Diable », conte musical pour enfants et
      familles, ainsi que « compromis rythmique no 3 », œuvre conceptuelle répétitive pour hackbrett, piano et
      vibraphone, d’une durée de 60 minutes.
    </p>

    <p>
      Son premier CD solo "Natur/Maschine" regroupant six oeuvres pour percussion écrites par des compositeurs suisses,
      est disponible en ligne depuis février 2014.
    </p>

    <p>
      Site internet :
      <a href="http://www.julienmegroz.ch" title="Site internet de Julien Mégroz" target="_blank"
        >www.julienmegroz.ch</a
      >
    </p>
  </div>

  <div class="comp_arr">
    <h2>Youri Rosset</h2>

    <picture>
      <source srcset="assets/20eme/YouriRosset.jpg" />
      <img src="assets/20eme/YouriRosset.jpg" alt="Portrait de Youri Rosset." />
    </picture>

    <p>
      Youri Rosset est né à Lausanne en 1994. Il commence la percussion à l’âge de huit ans dans la classe de Frédéric
      Rod à Epalinges, et depuis cette passion prendra toujours plus de place dans sa vie.
    </p>

    <p>
      Il étudie depuis 2012 dans la classe de percussions classiques de la HEMU Lausanne avec Stéphane Borel, Jacques
      Hostettler et Nicolas Suter. Il participe à des Masterclasses en particulier avec Keiko Abe, Nebojsa Zivkovic,
      Jean Geoffroy, Nick Wood et Mark Braafhart (Concertgebouw orchestra), et Nicolas Martynciow (orchestre de Paris).
      Il est actuellement en dernière année de Bachelor et a étudié pendant le premier semestre de l’année scolaire
      2014-2015 à la Hochschule für Musik Detmold (Allemagne) dans la classe de Peter Prommel.
    </p>

    <p>
      Il a fait partie du CPLC pendant 6 ans et a eu la chance de composer 3 oeuvres pour cet ensemble ces 3 dernières
      années.
    </p>

    <p>Son parcours est ponctué de nombreux prix dans le cadre de concours musicaux. Entre autre :</p>

    <ul>
      <li>
        1er prix lors du concours des jeunesses musicales Suisses, en duo avec Augustin Lipp, en 2012 (prix Fondation
        Thiébaud-Frey)
      </li>
      <li>
        1ère place en caisse-claire et 3ème place en marimba au concours de l’OSEW (Ostschweizer Solisten und Ensemble
        Wettbewerb) à Sirnach en 2010
      </li>
      <li>1ère place en timbales, 2ème place en xylophone au concours de l’OSEW à Sirnach en 2009</li>
    </ul>
  </div>
</div>
