import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  public activeSubmenu$: Observable<Submenu>;
  private _activeSubmenu$ = new BehaviorSubject<Submenu>(undefined);

  constructor() {
    this.activeSubmenu$ = this._activeSubmenu$.asObservable();
  }

  setActiveSubmenu(newActiveSubmenu: Submenu): void {
    this._activeSubmenu$.next(newActiveSubmenu);
  }
}

export const enum Submenu {
  CPLC = 'cplc',
  ARTISTES = 'artistes',
  CONCERTS = 'concerts',
  VINGTIEME = '20eme',
  MEMBRES = 'membres',
}
