<div class="container">
  <div class="row">
    <ng-container *ngFor="let media of medias; let i = index">
      <div *ngIf="i % COLUMNS_NUMBER === 0 && i !== 0" class="w-100 mb-4"></div>
      <div class="col align-self-center">
        <a (click)="open(media.isVideo, media.url)" class="media">
          <img *ngIf="!media.isVideo" src="https://cplc.ch/{{ media.url }}" />
          <video *ngIf="media.isVideo">
            <source src="https://cplc.ch/{{ media.url }}" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </a>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let i of [].constructor((COLUMNS_NUMBER - (medias?.length % COLUMNS_NUMBER)) % COLUMNS_NUMBER)"
    >
      <div class="col"></div>
    </ng-container>
  </div>
</div>
