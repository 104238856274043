<div class="container">
  <div class="row">
    <div class="col">
      <a href="http://www.portier-gaudin.ch/" target="_blank" alt="Site internet du magasin de musique Portier-Gaudin"
        ><picture>
          <source srcset="assets/20eme/LogoPortierGaudin.jpg" />
          <img src="assets/20eme/LogoPortierGaudin.jpg" alt="Logo du magasin de musique Portier Gaudin" /> </picture
      ></a>
    </div>
    <div class="col">
      <a href="http://www.loro.ch/" target="_blank" alt="Site internet de la Loterie romande"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoLoRo.jpg" />
          <img src="assets/20eme/LogoLoRo.jpg" alt="Logo de la Loterie romande" /> </picture
      ></a>
    </div>
    <div class="col">
      <a
        href="http://www.pour-cent-culturel-migros.ch/"
        target="_blank"
        alt="Site internet du Pour-cent culturel Migros"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoPour-CentCulturelMigros.jpg" />
          <img src="assets/20eme/LogoPour-CentCulturelMigros.jpg" alt="Logo du Pour-cent culturel Migros" /> </picture
      ></a>
    </div>

    <div class="w-100 mb-4"></div>

    <div class="col">
      <a href="http://www.bcv.ch/" target="_blank" alt="Site internet de la BCV"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoBCV.jpg" />
          <img src="assets/20eme/LogoBCV.jpg" alt="Logo de la BCV" /> </picture
      ></a>
    </div>
    <div class="col">
      <a href="http://www.alpiq.ch/" target="_blank" alt="Site internet d'Alpiq'"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoAlpiq.jpg" />
          <img src="assets/20eme/LogoAlpiq.jpg" alt="Logo d'Alpiq'" /> </picture
      ></a>
    </div>
    <div class="col">
      <a href="http://www.tourisme-rolle.ch/" target="_blank" alt="Site internet de l'office du tourisme de Rolle"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoRollePerleLeman.jpg" />
          <img src="assets/20eme/LogoRollePerleLeman.jpg" alt="Logo Rolle Perle du Leman" /> </picture
      ></a>
    </div>

    <div class="w-100 mb-4"></div>

    <div class="col">
      <a href="http://www.mont-sur-rolle.ch/" target="_blank" alt="Site internet de la commune de Mont-sur-Rolle"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoMontSurRolle.png" />
          <img src="assets/20eme/LogoMontSurRolle.png" alt="Logo commune de Mont-sur-Rolle" /> </picture
      ></a>
    </div>
    <div class="col">
      <a href="http://www.regionyon.ch" target="_blank" alt="Site internet du Conseil régional du district de Nyon"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoConseilRegionalNyon.jpg" />
          <img
            src="assets/20eme/LogoConseilRegionalNyon.jpg"
            alt="Logo Conseil régional du district de Nyon"
          /> </picture
      ></a>
    </div>
    <div class="col">
      <a href="http://www.boucherie-berlie.com/" target="_blank" alt="Site internet de la boucherie-charcuterie Berlie"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoBerlie.jpg" />
          <img src="assets/20eme/LogoBerlie.jpg" alt="Logo de laboucherie-charcuterie Berlie" /> </picture
      ></a>
    </div>

    <div class="w-100 mb-4"></div>

    <div class="col">
      <a href="https://menuiserie-grosjean.ch/" target="_blank" alt="Site internet de Grosjean et Cie SA"
        ><picture width="50%">
          <source srcset="assets/20eme/LogoGrosjean.jpg" />
          <img src="assets/20eme/LogoGrosjean.jpg" alt="Logo de Grosjean et Cie SA" /> </picture
      ></a>
    </div>
    <div class="col">
      <a href="javascript:;"
        ><picture>
          <source srcset="assets/20eme/LogoFondationJuchum.jpg" />
          <img src="assets/20eme/LogoFondationJuchum.jpg" alt="Logo Fondation Juchum" /> </picture
      ></a>
    </div>
    <div class="col">
      <a href="http://www.duperrex.ch/" target="_blank" alt="Site internet Duperrex transport"
        ><picture>
          <source srcset="assets/20eme/LogoDuperrex.jpg" />
          <img src="assets/20eme/LogoDuperrex.jpg" alt="Logo Duperrex transport" /> </picture
      ></a>
    </div>

    <div class="w-100 mb-4"></div>

    <div class="offset-4 col-4">
      <a href="http://www.pixelcreation.ch/" target="_blank" alt="Site internet de PixelCreation"
        ><picture>
          <source srcset="assets/20eme/LogoPixelCreation.jpg" />
          <img src="assets/20eme/LogoPixelCreation.jpg" alt="Logo de Pixel Creation" /> </picture
      ></a>
    </div>
  </div>
</div>
