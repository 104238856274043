<div>
  <p>Ceci est le calendrier des répétitions et des concerts du CPLC. Il est principalement destiné aux musiciens.</p>

  <p>
    En cliquant sur le bouton <img src="assets/+GoogleAgenda.gif" height="17" alt="Ajouter à Google Agenda" /> (en bas à
    droite du calendrier), vous aurez la possibilité d'ajouter ce calendrier à votre compte Google, si vous en possédez
    un.
  </p>

  <iframe
    class="mt-4 border-0 w-100"
    src="https://www.google.com/calendar/embed?src=vv029t977b0h0ohoh5eb27k474%40group.calendar.google.com&ctz=Europe/Zurich"
    height="650"
    frameborder="0"
    scrolling="no"
  ></iframe>
</div>
