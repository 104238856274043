import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccesMusiciensRoutingModule } from './acces-musiciens-routing.module';
import { CalendrierComponent } from './calendrier/calendrier.component';
import { TelechargementsComponent } from './telechargements/telechargements.component';

@NgModule({
  declarations: [CalendrierComponent, TelechargementsComponent],
  imports: [CommonModule, AccesMusiciensRoutingModule],
})
export class AccesMusiciensModule {}
