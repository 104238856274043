import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArtistesRoutingModule } from './artistes-routing.module';
import { MusiciensComponent } from './musiciens/musiciens.component';
import { SolistesComponent } from './solistes/solistes.component';

@NgModule({
  declarations: [MusiciensComponent, SolistesComponent],
  imports: [
    CommonModule,
    ArtistesRoutingModule
  ]
})
export class ArtistesModule { }
