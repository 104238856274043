<div class="w-75 text-center mx-auto">
  <picture class="d-block w-50 mx-auto mb-5">
    <source srcset="assets/20eme/TchikiDuo.jpg" />
    <img src="assets/20eme/TchikiDuo.jpg" alt="Tchiki Duo avec Jacques Hostettler et Nicolas Suter." />
  </picture>

  <div class="text-justify">
    <p>
      Fondé en 2006, le <a href="https://www.tchikiduo.com" alt="Tchiki Duo Project">Tchiki Duo</a> se distingue dès ses
      débuts par son aura et sa personnalité artistique, unique parmi l’élite mondiale des percussionnistes. Le duo mène
      depuis 2008 une carrière internationale avec des invitations provenant d’Europe, d’Asie et d’Amérique.
    </p>

    <p>
      Inspirés par leur amitié et portés par leur passion de la musique, Jacques Hostettler et Nicolas Suter mènent leur
      projet artistique en produisant des concerts/spectacles empreints d’émotions, d’originalité et de virtuosité.
    </p>

    <p>
      Ils connaissent un succès particulier en marimba duo, séduisant un public toujours plus nombreux avec un
      répertoire d'oeuvres de Keiko Abe et d’arrangements baroques.
    </p>

    <p>Le Tchiki duo : une fusion sonore et humaine hors du commun.</p>

    <p>
      Tchiki Duo est le concepteur et l'hôte de la Keiko Abe Lausanne International Marimba Academy (KALIMA), et
      enseigne à la Haute Ecole de Musique de Lausanne depuis 2012.
    </p>

    <p>Le projet Tchiki duo bénéficie du soutien officiel de La Ville de Lausanne depuis 2013.</p>
  </div>
</div>
