import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
  selector: 'app-directeur',
  templateUrl: './directeur.component.html',
  styleUrls: ['./directeur.component.scss'],
})
export class DirecteurComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Le directeur');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.CPLC);
  }
}
