import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MusiciensComponent } from './musiciens/musiciens.component';
import { SolistesComponent } from './solistes/solistes.component';

const routes: Routes = [
  { path: '', redirectTo: 'musiciens', pathMatch: 'full' },
  {
    path: 'musiciens',
    component: MusiciensComponent,
  },
  {
    path: 'solistes',
    component: SolistesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ArtistesRoutingModule {}
