import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/accueil', pathMatch: 'full' },
  {
    path: 'accueil',
    loadChildren: () => import('./accueil/accueil.module').then((m) => m.AccueilModule),
  },
  {
    path: 'cplc',
    loadChildren: () => import('./cplc/cplc.module').then((m) => m.CPLCModule),
  },
  {
    path: 'artistes',
    loadChildren: () => import('./artistes/artistes.module').then((m) => m.ArtistesModule),
  },
  {
    path: 'concerts',
    loadChildren: () => import('./concerts/concerts.module').then((m) => m.ConcertsModule),
  },
  {
    path: '20eme',
    loadChildren: () =>
      import('./vingtieme-anniversaire/vingtieme-anniversaire.module').then((m) => m.VingtiemeAnniversaireModule),
  },
  {
    path: 'commande-cd',
    loadChildren: () => import('./commande-de-cd/commande-de-cd.module').then((m) => m.CommandeDeCDModule),
  },
  {
    path: 'galerie',
    loadChildren: () => import('./galerie-photos/galerie-photos.module').then((m) => m.GaleriePhotosModule),
  },
  {
    path: 'accès-musiciens',
    loadChildren: () => import('./acces-musiciens/acces-musiciens.module').then((m) => m.AccesMusiciensModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
